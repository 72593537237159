
interface CheckingRoundType {
  key: string;
  value: string;
  text: string;
}
import { ref, onMounted, reactive, toRefs, defineComponent } from "vue";
import { FileItem } from "@/API/types";
import { message } from "ant-design-vue";
import {
  upImportCheckingResult,
  ImportCheckingResult,
} from "@/API/checking/SICCOCheckingDetail";

import axios from "axios";
export default defineComponent({
  emits: ["fresh-source-data"],
  setup(prop, ctx) {
    const formData = new FormData();
    const visible = ref(false);
    const isImport = ref(true);
    const isBeforeUpload = ref<boolean>(true);
    // 进度条
    const isShowUploadBtn = ref<boolean>(true);
    const isClickAbled = ref<boolean>(true);

    const curUploadFile = ref<object>({});
    const curPercent = ref<number>(0);
    const uploadFileSize = ref<string>("");
    const completeTips = ref<boolean>(false);

    //错误表格
    const hasError = ref(false);
    const tableHeight = ref(window.innerHeight - 360);
    // height: calc(100vh - 310px);
    const dataSource = ref<any[]>([
      {
        failedReason: 32,
        VIN: "西湖区湖底公园1号",
      },
    ]);

    const columns = ref<any[]>([
      {
        title: "VIN",
        dataIndex: "vinNo",
        key: "vinNo",
      },
      {
        title: "Failed Reason",
        dataIndex: "message",
        key: "message",
      },
    ]);

    const params = {
      offerTypeId: "",
      programId: "",
    };

    //关闭抽屉事件
    const closeThisDrawer = () => {
      visible.value = false;
    };
    //显示抽屉
    const showDrawer = (option: any) => {
      params.offerTypeId = option.offerTypeId;
      params.programId = option.programId;
      hasError.value = false;
      visible.value = true;
    };
    //重传
    const handelBrowse = () => {
      curPercent.value = 0;
      uploadFileSize.value = "";
      completeTips.value = false;
      isClickAbled.value = true;
      isShowUploadBtn.value = true;
      curUploadFile.value = {};
      isBeforeUpload.value = true;
      hasError.value = false;
      if (formData.has("file")) {
        formData.delete("file");
      }
    };

    //关闭抽屉的回调
    const afterVisibleChange = () => {
      isImport.value = true;
      curPercent.value = 0;
      uploadFileSize.value = "";
      completeTips.value = false;
      isClickAbled.value = true;
      isShowUploadBtn.value = true;
      curUploadFile.value = {};
      isBeforeUpload.value = true;
      if (formData.has("file")) {
        formData.delete("file");
      }
    };
    const loading = ref<boolean>(false);

    // 上传前
    const beforeUpload = (file: FileItem) => {
      const isXlsx =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        message.error("Only xlsx format files can be uploaded!");
      }
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        message.error("The file size should not be larger than 10MB!");
      }
      return isXlsx && isLt5M;
    };

    const uploadRequest = (source: any) => {
      if (formData.has("file")) {
        formData.delete("file");
      }
      formData.append("file", source.file);
      curUploadFile.value = source.file;
      uploadFileSize.value =
        source.file.size / 1024 > 1024
          ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
          : `${Math.floor(source.file.size / 1024)} KB`;
      isBeforeUpload.value = false;
    };

    // 上传文件
    const uploadFile = (source: any) => {
      isClickAbled.value = false;

      const param = {
        progDesignId: params.programId,
        offerTypeId: params.offerTypeId,
      };
      upImportCheckingResult(param, formData)
        .then((res: any) => {
          if (res.code === "0") {
            isImport.value = false;
          } else {
            if (res.code === "50056") {
              dataSource.value = res.data;
              hasError.value = true;
            } else {
              message.error(res.message);
            }
          }
          isShowUploadBtn.value = false;
          curPercent.value = 100;
          completeTips.value = true;
          isClickAbled.value = true;
        })
        .finally(() => {
          isShowUploadBtn.value = false;
        });
    };

    //ok按钮，刷新表格
    const freshTable = () => {
      isImport.value = true;
      const param = {
        progDesignId: params.programId,
        offerTypeId: params.offerTypeId,
      };
      ImportCheckingResult(param, formData).then((res: any) => {
        message.success("Import Successfully");
        ctx.emit("fresh-source-data");
        visible.value = false;
      });
    };

    return {
      uploadFile,
      loading,
      handelBrowse,
      freshTable,
      isShowUploadBtn,
      curUploadFile,
      curPercent,
      uploadFileSize,
      completeTips,
      isClickAbled,
      isBeforeUpload,
      beforeUpload,
      uploadRequest,
      visible,
      afterVisibleChange,
      isImport,
      closeThisDrawer,
      showDrawer,
      dataSource,
      columns,
      tableHeight,
      hasError,
    };
  },
});
