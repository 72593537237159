
interface CheckingRoundType {
  key: string;
  value: string;
  text: string;
}
import { ref, onMounted, reactive, toRefs, defineComponent } from 'vue';
import { FileItem } from '@/API/types';
import { message } from 'ant-design-vue';
import { uploadSourceData, addSourceData } from '@/API/checking/SICCOCheckingDetail';

import axios from 'axios';
export default defineComponent({
  emits: ['fresh-source-data'],
  setup(prop, ctx) {
    const formData = new FormData();
    const visible = ref(false);
    //两个下拉框的值
    const isBeforeUpload = ref<boolean>(true);
    // 进度条
    const isShowUploadBtn = ref<boolean>(true);
    const isClickAbled = ref<boolean>(true);

    const curUploadFile = ref<object>({});
    const curPercent = ref<number>(0);
    const uploadFileSize = ref<string>('');
    const completeTips = ref<boolean>(false);

    const params = {
      documentId: '',
      fileName: '',
      filePath: '',
      fileType: '',
      offerTypeId: '',
      programCode: ''
    };

    //关闭抽屉事件
    const closeThisDrawer = () => {
      visible.value = false;
    };
    //显示抽屉
    const showDrawer = (option: any) => {
      console.log(option);
      params.offerTypeId = option.offerTypeId;
      params.programCode = option.programCode;
      console.log(option);
      visible.value = true;
    };
    //重传
    const handelBrowse = () => {
      curPercent.value = 0;
      uploadFileSize.value = '';
      completeTips.value = false;
      isClickAbled.value = true;
      isShowUploadBtn.value = true;
      curUploadFile.value = {};
      isBeforeUpload.value = true;
      if (formData.has('document')) {
        formData.delete('document');
      }
    };

    //关闭抽屉的回调
    const afterVisibleChange = () => {
      curPercent.value = 0;
      uploadFileSize.value = '';
      completeTips.value = false;
      isClickAbled.value = true;
      isShowUploadBtn.value = true;
      curUploadFile.value = {};
      isBeforeUpload.value = true;
      if (formData.has('document')) {
        formData.delete('document');
      }
    };
    const loading = ref<boolean>(false);

    // 上传前
    const beforeUpload = (file: FileItem) => {
      let format = true;
      // const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (
        !(
          file.type === 'image/jpeg' ||
          file.type === 'application/pdf' ||
          file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        )
      ) {
        message.error('Only xlsx/pdf/jpg format files can be uploaded!');
        format = false;
      }
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        message.error('The file size should not be larger than 10MB!');
      }
      return format && isLt5M;
    };

    const uploadRequest = (source: any) => {
      if (formData.has('document')) {
        formData.delete('document');
      }
      formData.append('document', source.file);
      curUploadFile.value = source.file;
      uploadFileSize.value =
        source.file.size / 1024 > 1024
          ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB`
          : `${Math.floor(source.file.size / 1024)} KB`;
      isBeforeUpload.value = false;
    };

    // 上传文件
    const uploadFile = (source: any) => {
      isClickAbled.value = false;
      uploadSourceData(formData)
        .then((res: any) => {
          console.log(res);
          isShowUploadBtn.value = false;
          curPercent.value = 100;
          completeTips.value = true;
          isClickAbled.value = true;
          if (res.code === '0') {
            params.documentId = res.data.id;
            params.fileName = res.data.docName;
            params.filePath = res.data.docPath;
            params.fileType = res.data.docType;
          }
          message.success('Successfully uploaded');
        })
        .catch((error) => {
          console.log(error);
          isShowUploadBtn.value = false;
        });
    };

    //ok按钮，刷新表格
    const freshTable = () => {
      //刷新表格
      // ctx.emit('change-table')
      console.log(params);
      addSourceData(params).then((res: any) => {
        ctx.emit('fresh-source-data');
        params.documentId = '';
        params.fileName = '';
        params.filePath = '';
        params.fileType = '';

        visible.value = false;
      });
    };
    return {
      uploadFile,
      loading,
      handelBrowse,
      freshTable,
      isShowUploadBtn,
      curUploadFile,
      curPercent,
      uploadFileSize,
      completeTips,
      isClickAbled,
      isBeforeUpload,
      beforeUpload,
      uploadRequest,
      visible,
      afterVisibleChange,
      closeThisDrawer,
      showDrawer
    };
  }
});
