
import {
  checkingApprove,
  checkingReject,
  checkingPending,
  getTagApi,
  getDescirptionApi,
  getInternalCommentsApi,
} from "@/API/checking/SICCOCheckingDetail";
import { message } from "ant-design-vue";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import {
  ref,
  onMounted,
  nextTick,
  reactive,
  toRefs,
  defineComponent,
  computed,
} from "vue";
export default defineComponent({
  emits: ["fresh-table-data"],
  setup(prop, ctx) {
    const visible = ref(false);

    const finalBonus = ref("");
    const comments = ref("");
    type Key = ColumnProps["key"];
    const type = ref<string>("");
    const ids = ref<Key[]>([]);
    let exemptionStatusParam : true | undefined = undefined
    const isAll = ref(false);
    const isSave = ref(false);

    const tagCN = ref<null | string>(null);
    const descirption = ref<null | string>(null);
    const commentInput = ref<null | string>(null);
    const internalComments = ref("");

    //加载数据状态
    const spinning = ref(false);
    //两个动态下拉框
    const dataTag = ref<any[]>([]);
    const dataDescirption = ref<any[]>([]);

    const option = ref<any>({});

    const showOperationBySelect = (op: string, item: any, exemptionStatus?: true) => {
      ids.value = [item.id];
      option.value = item;
      type.value = op;
      finalBonus.value = "";
      comments.value = "";
      internalComments.value = "";
      dataTag.value = [];
      dataDescirption.value = [];
      tagCN.value = null;
      descirption.value = null;
      commentInput.value = null;
      isSave.value = false;
      isAll.value = false;
      exemptionStatusParam = exemptionStatus;
      getInternalCommentsApi({
        programId: item.progDesignId,
        dealerCode: item.dealerCode,
        vinNo: item.vinNo,
        id: item.id,
      }).then((res) => {
        internalComments.value = res;
        visible.value = true;
      });
    };

    const showOperation = (op: string, selectedRowKeys: Array<Key>) => {
      ids.value = [];
      finalBonus.value = "";
      comments.value = "";
      isSave.value = false;
      type.value = op;
      if (type.value === "Pending" || type.value === "Reject") {
        ids.value = selectedRowKeys;
      }
      if (undefined !== selectedRowKeys) {
        ids.value = selectedRowKeys;
        isAll.value = false;
      } else {
        isAll.value = true;
      }

      // let param = {
      //     programId:
      //     dealerCode:
      //     vinNo:
      // }

      // getInternalCommentsApi(param).then((res) => {
      //     visible.value = true;
      // })
    };

    const save = () => {
      if (
        finalBonus.value === "" ||
        finalBonus.value === undefined ||
        finalBonus.value === null
      ) {
        message.error("Please input Final Bonus ");
        return;
      }
      // if (internalComments.value === '' || internalComments.value === undefined || internalComments.value === null){
      //     message.error('Please input internalComments Comments')
      //     return;
      // }
      if (internalComments.value.length > 1000) {
        message.error(
          "internalComments Comments too long, sub allowed 1000 bytes"
        );
        return;
      }

      if (comments.value.length > 1000) {
        message.error("Checking Comments too long, sub allowed 1000 bytes");
        return;
      }
      if (isNaN(parseFloat(finalBonus.value))) {
        message.error("Please enter Final bonus greater than or equal to 0");
        return;
      }
      // else if (parseFloat(finalBonus.value)<0){
      //     message.error('Please enter Final bonus greater than or equal to 0')
      //     return;
      // }
      if (type.value === "Pending" || type.value === "Reject") {
        if (
          comments.value === "" ||
          comments.value === undefined ||
          comments.value === null
        ) {
          message.error("Please input Checking Comments");
          return;
        }
        if (type.value === "Pending") {
          const params = {
            checkingComments: comments.value,
            internalComments: internalComments.value,
            comments:
              internalComments.value.trim().length > 0
                ? internalComments.value + "\n" + comments.value
                : comments.value,
            finalBonus: finalBonus.value,
            ids: ids.value,
            exemptionStatus: exemptionStatusParam
          };
          isSave.value = true;
          checkingPending(params).then((res) => {
            console.log(res);
            if (res.code === "0") {
              message.success("Operation succeeded");
              ctx.emit("fresh-table-data");
            } else {
              message.error(res.message);
            }
            visible.value = false;
          });
        }

        if (type.value === "Reject") {
          const params = {
            checkingComments: comments.value,
            internalComments: internalComments.value,
            comments:
              internalComments.value.trim().length > 0
                ? internalComments.value + "\n" + comments.value
                : comments.value,
            finalBonus: finalBonus.value,
            ids: ids.value,
            exemptionStatus: exemptionStatusParam
          };
          isSave.value = true;
          checkingReject(params).then((res) => {
            if (res.code === "0") {
              message.success("Operation succeeded");
              ctx.emit("fresh-table-data");
            } else {
              message.error(res.message);
            }
            visible.value = false;
          });
        }
      }

      if (type.value === "Approve") {
        if (isAll.value) {
          const params = Object.assign(
            {
              checkingComments: comments.value,
              internalComments: internalComments.value,
              comments:
                internalComments.value.trim().length > 0
                  ? internalComments.value + "\n" + comments.value
                  : comments.value,
              finalBonus: finalBonus.value,
              ids: [],
              exemptionStatus: exemptionStatusParam
            },
            option.value
          );
          // const params = {
          //     comments: comments.value,
          //     finalBonus: finalBonus.value,
          //     ids: []
          // }
          console.log(params);
          isSave.value = true;
          checkingApprove(params).then((res) => {
            if (res.code === "0") {
              message.success("Operation succeeded");
              ctx.emit("fresh-table-data");
            } else {
              message.error(res.message);
            }
            visible.value = false;
          });
        } else {
          const params = {
            checkingComments: comments.value,
            internalComments: internalComments.value,
            comments:
              internalComments.value.trim().length > 0
                ? internalComments.value + "\n" + comments.value
                : comments.value,
            finalBonus: finalBonus.value,
            ids: ids.value,
            exemptionStatus: exemptionStatusParam
          };
          isSave.value = true;
          checkingApprove(params).then((res) => {
            // console.log(res)
            if (res.code === "0") {
              message.success("Operation succeeded");
              ctx.emit("fresh-table-data");
            } else {
              message.error(res.message);
            }
            visible.value = false;
          });
        }
      }
    };

    const cancel = () => {
      comments.value = "";
      finalBonus.value = "";
      internalComments.value = "";
      tagCN.value = "";
      descirption.value = "";
      visible.value = false;
    };

    const noTag = computed(() => {
      if (
        tagCN.value === undefined ||
        tagCN.value === null ||
        tagCN.value === ""
      ) {
        return true;
      } else {
        return false;
      }
    });

    const getTag = (val: string) => {
      spinning.value = true;
      getTagApi({ commentsSearch: val }).then((res) => {
        dataTag.value = [];
        spinning.value = false;
        if (res.length === 0) {
          message.info("No data found");
          return;
        }
        const arr = [];
        for (let i = 0; i < res.length; i++) {
          arr.push({
            value: res[i],
            label: res[i],
          });
        }
        dataTag.value = arr;
      });
    };

    const getDescirption = (val: string) => {
      spinning.value = true;
      getDescirptionApi({ tag: tagCN.value, commentsSearch: val }).then(
        (res) => {
          dataDescirption.value = [];
          spinning.value = false;
          if (res.length === 0) {
            message.info("No data found");
            return;
          }
          const arr = [];
          for (let i = 0; i < res.length; i++) {
            arr.push({
              value: res[i],
              label: res[i],
            });
          }
          dataDescirption.value = arr;
        }
      );
    };

    let timeout: any;
    const handleSearch = (val: string, flag: string) => {
      if (val.length === 0) {
        if (flag === "tag") {
          dataTag.value = [];
          dataDescirption.value = [];
        } else {
          dataDescirption.value = [];
        }
        return;
      }
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      switch (flag) {
        case "tag":
          timeout = setTimeout(() => {
            getTag(val);
          }, 1000);
          break;
        case "descirption":
          // dataDescirption.value = [{value: 'ccc',label: 'ccc',},{value: 'ddd',label: 'ddd',}]
          timeout = setTimeout(() => {
            getDescirption(val);
          }, 1000);
          break;
      }
    };
    const handleChange = (val: string, flag: string) => {
      switch (flag) {
        case "tag":
          tagCN.value = val;
          descirption.value = null;
          dataTag.value = [];
          break;
        case "descirption":
          descirption.value = val;
          dataDescirption.value = [];
          break;
      }
    };

    const clearComments = () => {
      comments.value = "";
      tagCN.value = null;
      descirption.value = null;
      commentInput.value = "";
      dataTag.value = [];
      dataDescirption.value = [];
    };

    const addComments = () => {
      if (
        tagCN.value === undefined ||
        tagCN.value === null ||
        tagCN.value === ""
      ) {
        message.error("Please select Tag_CN");
        return;
      }
      if (
        descirption.value === undefined ||
        descirption.value === null ||
        descirption.value === ""
      ) {
        message.error("Please select Descirption");
        return;
      }
      if (
        commentInput.value === undefined ||
        commentInput.value === null ||
        commentInput.value === ""
      ) {
        message.error("Please input Comments");
        return;
      }

      if (comments.value.length === 0) {
        comments.value =
          tagCN.value +
          "-" +
          descirption.value +
          "-" +
          commentInput.value +
          "；";
      } else {
        comments.value =
          comments.value +
          "\n" +
          tagCN.value +
          "-" +
          descirption.value +
          "-" +
          commentInput.value +
          "；";
      }
    };

    return {
      showOperation,
      visible,
      finalBonus,
      comments,
      type,
      save,
      isSave,
      cancel,
      showOperationBySelect,
      tagCN,
      descirption,
      internalComments,
      commentInput,
      clearComments,
      dataTag,
      handleSearch,
      handleChange,
      dataDescirption,
      addComments,
      noTag,
      spinning,
    };
  },
});
